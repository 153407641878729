<template>
   <main v-if="form">
       <div class="pb">
            <Button icon="md-return-left" shape="circle" @click="$router.back()">返回</Button>
        </div>

        <Form ref="form" :model="form" label-position="top">
            <Divider orientation="left">广告内容</Divider>
            <Row>
                <Col :span="9" :offset="3">
                    <template v-if="form.type == 0 && !(this.form.value instanceof Array)">
                        <FormItem label="图片">
                            <upload-image
                                class="w50-max"
                                name="ad"
                                v-model="form.value.src"
                            />
                        </FormItem>
                        <FormItem label="标题">
                            <Input v-model="form.value.title" class="w75" placeholder="请输入标题"></Input>
                        </FormItem>
                        <template v-if="form.link">
                            <FormItem label="跳转类型">
                                <RadioGroup v-model="form.value.target" type="button" style="width: 600px">
                                    <Radio :label="0">不跳转</Radio>
                                    <Radio :label="1">小程序地址-一级页面</Radio>
                                    <Radio :label="2">小程序地址-二级页面</Radio>
                                    <Radio :label="3">链接地址</Radio>
                                    <Radio :label="4">小程序功能</Radio>
                                </RadioGroup>
                            </FormItem>
                            <FormItem label="跳转地址">
                                <Input v-model="form.value.to" class="w75" placeholder="留空表示不跳转"></Input>
                            </FormItem>
                            <FormItem label="常用小程序地址" v-show="form.value.target==1||item.target==2">
                                <Select v-model="form.value.to" class="w50">
                                    <Option
                                        :value="item.path"
                                     v-for="item in paths"
                                        :key="item.path"
                                    >{{ item.pagename }}</Option>
                                </Select>
                            </FormItem>
                        </template>
                    </template>

                    <template v-if="form.type == 1">
                        <FormItem>
                            <Button icon="md-add-circle" shape="circle" @click="addImage">添加图片</Button>
                        </FormItem>
                        <div v-for="(item, index) in form.value" :key="index">
                            <Divider />
                            <FormItem label=" ">
                                <upload-image
                                    class="w50-max"
                                    name="ad"
                                    v-model="item.src"
                                />
                            </FormItem>
                            <FormItem label="标题">
                                <Input v-model="item.title" class="w75" placeholder="请输入标题"></Input>
                            </FormItem>
                            <FormItem label="跳转类型">
                                <RadioGroup v-model="item.target" type="button" style="width: 600px">
                                    <Radio :label="0">不跳转</Radio>
                                    <Radio :label="1">小程序地址-一级页面</Radio>
                                    <Radio :label="2">小程序地址-二级页面</Radio>
                                    <Radio :label="3">链接地址</Radio>
                                    <Radio :label="4">小程序功能</Radio>
                                </RadioGroup>
                            </FormItem>
                            <FormItem label="跳转地址" v-if="form.link && item.target">
                                <Input v-model="item.to" class="w75" placeholder="留空表示不跳转"></Input>
                                <Button type="error" icon="md-trash" class="ml" @click="remove(index)"></Button>
                            </FormItem>
                            <FormItem label="常用小程序地址" v-show="item.target==1||item.target==2">
                                <Select v-model="item.to" class="w50">
                                    <Option
                                        :value="item.path"
                                        v-for="item in paths"
                                        :key="item.path"
                                    >{{ item.pagename }}</Option>
                                </Select>
                            </FormItem>
                            <FormItem label="状态">
                                <i-switch v-model="item.show" size="large">
                                    <span slot="open">显示</span>
                                    <span slot="close">隐藏</span>
                                </i-switch>
                            </FormItem>
                            <ButtonGroup>
                                <Button icon="md-arrow-up" @click="sort(index, 'up')"></Button>
                                <Button icon="md-arrow-down" @click="sort(index, 'down')"></Button>
                            </ButtonGroup>
                        </div>
                    </template>

                    <template v-if="form.type == 2  && !(this.form.value instanceof Array)">
                        <FormItem label="视频">
                            <upload-video
                                class="w50-max"
                                name="ad"
                                v-model="form.value.src"
                            />
                        </FormItem>
                        <FormItem label="自动播放">
                            <i-switch v-model="form.value.autoplay" size="large">
                                <span slot="open">是</span>
                                <span slot="close">否</span>
                            </i-switch>
                        </FormItem>
                        <FormItem label=" ">
                            <Button type="error" icon="md-trash" @click="remove()"></Button>
                        </FormItem>
                    </template>

                    <template v-if="form.type == 3  && !(this.form.value instanceof Array)">
                        <FormItem label="文字内容">
                            <Input type="textarea" v-model="form.value.content"></Input>
                        </FormItem>
                        <FormItem label="跳转地址" v-if="form.link">
                            <Input v-model="form.value.to" class="w75" placeholder="留空表示不跳转"></Input>
                            <Button type="error" icon="md-trash" class="ml" @click="remove()"></Button>
                        </FormItem>
                    </template>
                </Col>
            </Row>

            <Divider />
            <Row>
                <Col :span="9" :offset="3">
                    <FormItem label="状态">
                        <i-switch v-model="form.status" size="large" :true-value="1" :false-value="0">
                            <span slot="open">显示</span>
                            <span slot="close">隐藏</span>
                        </i-switch>
                    </FormItem>
                    <FormItem>
                        <Button type="success" icon="md-filing"  @click="submit">保存修改</Button>
                    </FormItem>
                </Col>
            </Row>
        </Form>
   </main>
</template>

<script>
    import UploadImage from '@/components/UploadImage'
    import UploadVideo from '@/components/UploadVideo'

    export default {
        components: {
            UploadImage,
            UploadVideo
        },
        data()
        {
            return {
                id: this.$route.params.id,
                form: null,
                paths:[],
            }
        },
        created()
        {
            this.loadData()
            this.loadPaths()
        },
        methods: {
            submit()
            {
                this.$http.post('/ad/update/id/' + this.id, this.form)
                    .then(res => {
                        this.$router.back()
                    })
            },
            loadData()
            {
                this.$http.get('/ad/detail/id/' + this.id)
                    .then(res => {
                        if (res.value.length === 0)
                        {
                            switch (res.type)
                            {
                                case 0:
                                    res.value = {
                                                    title :'',
                                                    src   : '',
                                                    target: 0,
                                                    to    : '',
                                                }
                                    break
                                
                                case 1:
                                    res.value = [{
                                                    title :'',
                                                    src   : '',
                                                    target: 0,
                                                    to    : '',
                                                    show   :true
                                                }]
                                    break

                                case 2:
                                    res.value = { src: '', autoplay : false }
                                    break

                                case 3:
                                    res.value = {
                                                    content: '',
                                                    target : 0,
                                                    to     : ''
                                                }
                                    break

                                default:
                                    res.value = []
                                    break
                            }
                        }
                        else
                        {
                            if (!res.link)
                            {
                                switch (res.type)
                                {
                                    case 0:
                                        res.value.target = 0
                                        res.value.to     = ''
                                        break
                                    
                                    case 1:
                                        for (var i in res.value)
                                        {
                                            res.value[i].target = 0
                                            res.value[i].to     = ''
                                        }
                                        break

                                    case 2:
                                        
                                        break

                                    case 3:
                                        res.value.target = 0
                                        res.value.to     = ''
                                        break

                                    default:
                                        
                                        break
                                }
                            }
                        }

                        this.form = res
                        if(res.type==1&&res.value.length>0){
                            for (var i in res.value)
                            {
                                if(res.value[i].show==null){
                                    res.value[i].show = true
                                }
                                
                            }
                        }
                    })
            },
            loadPaths()
            {
                this.$http.get('/wxPages/list')
                    .then(res => {
                        this.paths  = res.data
                    })
            },
            addImage()
            {
                this.form.value.push({
                    src: '',
                    to : '',
                    show   :true
                })
            },
            remove(index)
            {
                if (this.form.type == 1)
                    this.form.value.splice(index, 1)
                else
                    this.form.value = []
            },
            sort(index, mode)
            {
                if (mode == 'up' && index == 0)
                    return

                if (mode == 'down' && index == this.form.value.length - 1)
                    return

                var item = this.form.value[index]
                if (mode == 'up')
                {
                    this.form.value[index] = this.form.value[index - 1]
                    this.form.value[index - 1] = item
                }
                else
                {
                    this.form.value[index] = this.form.value[index + 1]
                    this.form.value[index + 1] = item
                }

                this.$forceUpdate()
            }
        }
    }
</script>