<template>
    <section>
        <Upload
            type="drag"
            :name="name"
            :multiple="false"
            :show-upload-list="false"
            :format="format"
            :max-size="maxSize"
            :action="action"
            :headers="headers"
            :on-progress="uploading"
            :on-success="success"
            :on-error="error"
            :on-format-error="formatError"
            :on-exceeded-size="sizeError"
        >
            <div class="pd-xs radius">
                <div class="pd-tb" v-if="loading">
                    <Spin>
                        <Icon type="ios-loading" size="36" class="spin-icon-load"></Icon>
                        <p>Uploading</p>
                    </Spin>
                </div>
                <div v-else>
                    <div  class="pos-rel" v-if="preview">
                         <Icon type="md-close-circle" class="trash-video"  size="16" @click.stop="delVideo"></Icon>
                         <video class="responsive-img" :src="preview" controls/>
                    </div>
                    <template v-else>
                        <Icon type="md-cloud-upload" size="26" class="text-primary" />
                        <p class="text-blue-grey" v-if="text">{{ text }}</p>
                    </template>
                </div>
            </div>
        </Upload>
    </section>
</template>

<script>
    export default {
        props: {
            value: {
                type: String,
                required: true
            },
            name: {
                type: String,
                default: 'video'
            },
            maxSize: {
                type: Number,
                default: 0
            },
            format: {
                type: Array,
                default()
                {
                    return ['mp4']
                }
            },
            text: {
                type: String,
                default: '上传视频'
            }
        },
        data()
        {
            return {
                currentValue : this.value,
                preview      : false,
                action       : this.$http.defaults.baseURL + '/upload/video',
                headers      : { Authorization: window.localStorage.getItem('token') },
                loading      : false
            }
        },
        created()
        {
            
        },
        mounted()
        {
            this.showPreview()
        },
        methods: {
            uploading(event, file)
            {
                this.loading = true
            },
            success(res, file)
            {
                if (res.status == 200)
                {
                    this.currentValue = res.data[this.name]
                    this.updateValue()
                }
                else
                {
                    this.$Modal.error({ title: '上传失败', content: res.message })
                }

                this.loading = false
            },
            error(error, file)
            {
                this.loading = false

                this.$Modal.error({
                    title : '上传失败',
                    content  : error
                })
            },
            formatError(file)
            {
                this.$Modal.warning({
                    title   : '格式不支持',
                    content : '只允许上传 ' + this.format.join('、') + ' 格式的视频文件'
                })
            },
            sizeError(file)
            {
                this.$Modal.warning({
                    title   : '超出文件大小限制',
                    content : '文件不能大于' + (this.maxSize / 1024) + 'M'
                })
            },
            showPreview()
            {
                this.preview = this.currentValue
            },
            delVideo() {
                 this.preview = false
                this.currentValue = ''
                this.updateValue()
            },
            updateValue()
            {
                this.$emit('input', this.currentValue)
            }
        },
        watch: {
            value(value)
            {
                this.currentValue = this.value
                this.showPreview()
            },
            currentValue(value)
            {
                this.showPreview()
            }
        }
    }
</script>
<style scoped>
.trash-video {
    position: absolute;
    top: -8px;
    right: -8px;
    cursor: pointer;
}
</style>